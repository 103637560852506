import { imageAdapter } from '@/adapters/constructor/utils/image';

const columnsAdapter = (data) => {
  return {
    chart: imageAdapter(data.chart, data.chart_mobile),
    text: data.text,
  };
};

export default ({ data }) => {
  return {
    columns: data?.columns.map(columnsAdapter),
  };
};
